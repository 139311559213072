import "./styles.css";

import PartySocket from "partysocket";
import {unpack} from "msgpackr/unpack";

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

//const players = new Map<string, { position: [x: number, y: number, z: number] }>()

/*function updatePosition(playerId: string, pos: [x: number, y: number, z: number]) {
  const player = players.get(playerId);
  if (player) {
    player.position = pos;
    updateHTML(players);
  } else {
    console.error("player not found");
  }
}*/

function updateHTML(players: any) {
  // map players to a html output - having one div for every player + position

  const html = Object.entries(players).map(([playerId, player]) => {
    return `<div>${playerId} -----> ${player.position.map(v => v.toFixed(2))}</div><br/>`
  });

  output.innerHTML = html.join("");
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});


// You can even start sending messages before the connection is open!
conn.addEventListener("message", async (event: Event & {data: Blob}) => {
  //console.log(`Received Message: ${event.data}`);
  const players = unpack(await event.data.arrayBuffer());
  console.log(players);
  updateHTML(players);
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  console.log("Connected to Server!");
});
